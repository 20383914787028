import Card from "./Card";
import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function HeroSection() {
  const heading3 = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      heading3.current,
      { scale: 0.8 },
      {
        scale: 1.1,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: heading3.current,
          start: "top 80%",
          end: "top 20%",

          scrub: 1,
        },
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Cardcontent = [
    {
      img: "/assets/img/schedule-img.png",
      altText: "First card",
      title: "Schedule a pickup",
    },
    {
      img: "/assets/img/pickup-img.png",
      altText: "Second card",
      title: "Pickup at your Address",
    },
    {
      img: "/assets/img/recieve-payment.png",
      altText: "Third card ",
      title: "Receive payment",
    },
  ];

  return (
    <div>
      {/* <div class="grid gap-3">
  <div class="p-2 g-col-6"><h1><span className='span-style'>CLEAN UP</span> YOUR SPACE, <br/>CASH IN YOUR <span className='span-style'>SCRAP!</span></h1></div>
  <div class="p-2 g-col-6"><img src='assets/img/hero-img.png' alt='hero-img'/></div>
  
</div> */}

      <div className="container-fluid overflow-hidden">
        <div className="row hero-img">
          <div className="col-12 mt-5 ">
            <h1 className="ps-4 hero-text ">
              <span className="span-style">CLEAN UP</span> <br />
              <span className="span-style">
                YOUR SPACE, <br />
                CASH IN <br /> YOUR <span class="span-style">SCRAP!</span>
              </span>
            </h1>
          </div>
        </div>
      </div>

      {/* ----------how it works---------- */}
      <div className="container mt-2">
        <h2 className="text-center my-3" ref={heading3}>
          {" "}
          How it <span class="green">works</span>
        </h2>
        <div className="row d-flex flex-lg-row">
          {Cardcontent.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <Card item={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
