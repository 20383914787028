import Card from "./Card";
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function ServicesSection(){

    const heading4 = useRef(null);
   

    useEffect(() => {
        gsap.fromTo(
          heading4.current,
          { scale: 0.8 },
          {
            scale: 1.2,
            duration: 1,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: heading4.current,
              start: 'top 80%',
              end: 'top 20%',

              scrub: 1,
            },
          }
        ); 
    
      }, []);

      
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 
  const Cardcontent2 = [
    { img: "/assets/img/service-img1.png", title: "Doorstep Pickup" },
    { img: "/assets/img/service-img2.png", title: "Accurate Valuations" },
    {
      img: "/assets/img/service-img3.png",
      title: "Trained & Verified Pickup Staff",
    },
    { img: "/assets/img/service-img4.png", title: "Eco-Friendly" },
    { img: "/assets/img/service-img5.png", title: "Waste Management" },
  ];
  return (
    <div className="container mt-5 overflow-hidden">
      <h2 className="text-left text-center" ref={heading4}>
        Our <span className="green">Services</span>
      </h2>
       <p className="text-center">
        We are committed to enhancing waste management efficiency by delivering
        seamless and reliable solutions. With a focus on transparency and
        safety, we ensure a hassle-free experience, allowing you to manage scrap
        collection with ease and confidence.
      </p> 
      <div className="row d-flex flex-lg-row justify-content-center">
        {Cardcontent2.map((item, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <Card item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}
