import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function WhyUs() {

    const heading = useRef(null);
    const heading1 = useRef(null);

    useEffect(() => {
        gsap.fromTo(
          heading.current,
          { scale: 0.8 },
          {
            scale: 1.1,
            duration: 1,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: heading.current,
              start: 'top 80%',
              end: 'top 20%',

              scrub: 1,
            },
          }
        ); 
        gsap.fromTo(
            heading1.current,
            { scale: 0.8 },
            {
              scale: 1.1,
              duration: 1,
              ease: 'power2.out',
              scrollTrigger: {
                trigger: heading1.current,
                start: 'top 80%',
                end: 'top 20%',
  
                scrub: 1,
              },
            }
          ); 
         
       
       
      }, []);

      
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <div className="parallax overflow-hidden" id='about'>
            <div class="container my-3 heading">
                <h2 className='text-center pt-3' ref={heading}>Why <span className='span-style' >Us?</span></h2>
                <h2 className='text-center pb-3 span-style f-20' ref={heading1}>Building a Sustainable Future with ScrapItUp</h2>
                <div class="row justify-content-center text-center">

                    <div class="col-lg-4 col-md-12 col-sm-12 mb-5 ">
                        <div><h4 className="mb-5 span-style">Driving the Circular Economy</h4></div>

                        <div><h4 className="mb-5 span-style">Minimizing Environmental Impact</h4></div>
                        <div>
                            <h4 className="span-style">Promoting Sustainability</h4>
                        </div>

                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 recycle-gif d-flex justify-content-center mb-5 ">
                        <img src="/assets/img/recycle-img.png" className="img-fluid recycle-animation" /> 
                    </div>
 

                    <div class="col-lg-4 col-md-12 col-sm-12 mb-5">
                        <div><h4 className="mb-5 span-style">Innovative & Cost-Effective Solutions</h4></div>

                        <div><h4 className="mb-5 span-style">Extending Product Life Cycles</h4></div>
                        <div>
                            <h4 className="span-style">Closing the Waste Loop</h4>
                        </div>

                    </div>
                </div>


                {/* <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-8 col-sm-12 d-flex justify-content-center recycle-gif">
                        <img src="/assets/img/recycle-gif.gif" class="img-fluid" />
                    </div>
                </div> */}


            </div>
            {/* //   ----------------------------------------companies----------------------------------------------- */}




        </div>

    );
}
