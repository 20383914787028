import React from "react";

export default function TrustedCompanies() {
  return (
    <div>
      <div className="text-center trusted-by-leading-brands ">
        
        <h2 className="white-text p-2"> 
          Trusted by leading brands, </h2>
          <h2 className="white-text f-16">ScrapItUp helps you manage your scrap efficiently </h2>
      
        <div className="mt-4 white-bg">
          <div className="container my-2">
            
            <div className="row justify-content-evenly brand-bar align align-items-center">
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 15.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 16.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 17.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 18.png"
                  alt="brands"
                />
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
