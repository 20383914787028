import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="bg-light text-dark ">
        <div className="footer-section pt-5">
          <div className="container text-md-left">
            <div className="row">
              {/* Footer Links Column 1 */}

              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <a href="#">
                  <img
                    src="/assets/img/scrapituplogotemp.png"
                    alt="Logo"
                    className="logo p-2"
                    width="100"
                  />
                </a>

                <h6 className="text-uppercase fw-bold">Get to Know Us</h6>
                {/* <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#45B348",
                    height: "2px",
                  }}
                /> */}
                <a href="#" className="text-dark mx-2">
                  <i className="fa fa-facebook" />
                </a>

                <a href="#" className="text-dark mx-2">
                  <i className="fa fa-instagram" />
                </a>
                <br />
              </div>

              {/* Footer Links Column 2 */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Connect with Us</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#45B348",
                    height: "2px",
                  }}
                />
                <p>
                  <a
                    href="tel:+918595358613"
                    className="text-dark d-block mb-2"
                  >
                    <i className="fa fa-phone me-2" aria-hidden="true"></i>
                    +91-8595358613
                  </a>
                  <a
                    href="mailto:team@scrapitup.com"
                    className="text-dark d-block mb-2"
                  >
                    <i className="fa fa-envelope-o me-2" aria-hidden="true"></i>
                    team@scrapitup.com
                  </a>
                  <span className="text-primary d-block">
                    Available from 10am - 7pm
                  </span>
                </p>
              </div>

              {/* Footer Links Column 3 */}
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">Make Money with Us</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#45B348",
                    height: "2px",
                  }}
                />
                <p className="text-dark">
                  <h6 className="green">
                   
                    <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                    Address
                  </h6>
                  Scrap it up, Bhopal, mp 462001
                </p>
                <p>Download the ScrapitUp App</p>
                <img
                  src="/assets/img/playstore.png"
                  alt="get on playstore"
                  class="img-fluid"
                />
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
