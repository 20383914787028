import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function ClientSays() {


    const heading7 = useRef(null);
   

    useEffect(() => {
        gsap.fromTo(
          heading7.current,
          { scale: 0.8 },
          {
            scale: 1,
            duration: 1,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: heading7.current,
              start: 'top 80%',
              end: 'top 20%',

              scrub: 1,
            },
          }
        ); 
    
      }, []);

      
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



    const testimonials = [
        {
          name: "Shajahan",
          feedback: "The ScrapituP Team was really professional and their services the best that I have seen in my entire life. Could not have asked for anything more. Simply awesome "
        },
        {
          name: "John Doe",
          feedback: "Fantastic service! They made the whole process so easy and stress-free. Highly recommended!"
        },
        {
          name: "Jane Smith",
          feedback: "I was impressed by their professionalism and efficiency. Great job, ScrapituP Team!"
        },
        {
          name: "Ahmed Khan",
          feedback: "Their attention to detail is unmatched. I couldn't be happier with the service I received!"
        },
        {
          name: "Emily Johnson",
          feedback: "ScrapituP offers excellent customer service and reliable solutions. Will use them again!"
        }
      ];
      


  return (
    <div className="container-fluid">
      <h2 className="mt-3 text-center"ref={heading7}>What Clients <span className='green'>Say</span></h2>
      <p className="mb-5 text-center">Our clients appreciate the convenience of our doorstep pickups and the
      transparency offered by our digital weighing system. They trust our
      thoroughly vetted staff and commend the efficiency of our services, making
      scrap collection a seamless experience for both residential and commercial
      needs</p>  
      <div className="row p-3 testimonials-row">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="col-lg-4 col-md-6 col-sm-12 mb-5 d-flex justify-content-center testimonial-col"
          >
            <div className="position-relative testimonials-card-container mb-4">
              <div className="background-layer"></div>
              <div className="card shadow p-3 rounded-5 testimonials-card">
                <div className="card-body text-center">
                  <h4 className="card-title">{testimonial.name}</h4>
                  <p className="card-text">{testimonial.feedback}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}