import React from "react";

export default function ContactUs() {
  return (
    <div className="" id="contact">
      <div className="container-fluid ">
        <div className="row bg-green">
          <div className="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center px-0">
          <iframe
          className="map-data"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234700.73463944453!2d77.0940286602867!3d23.199418077284804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c428f8fd68fbd%3A0x2155716d572d4f8!2sBhopal%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728464828095!5m2!1sen!2sin"
             width="100%"
              height="500"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
         
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 p-5 contact-us-card">
            <div className="card shadow-card border-0 rounded-5">
              <div className="card-header text-center ">
                <h2>
                  Contact<span className="text-primary"> Us</span>
                </h2>
                <h4 className="f-20 ">Get in touch with us</h4>
              </div>
              <div className="card-body">
                <h4 className="f-16 p-2">
                  <i className="fa fa-phone mx-2" aria-hidden="true"></i>
                  +91-8982759856
                  <br/>
                  <span style={{ marginLeft: "30px" }}>+91-8982759856</span>
                </h4>
                <h4 className="f-16 p-2">
                  <i className="fa fa-envelope-o me-2" aria-hidden="true"></i>
                  team@scrapitup.in
                </h4>
                <h4 className="f-16 p-2">
                  <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                  Scrapitup, near D-Mart, Vidya Nagar, Bhopal, Madhya Pradesh
                  462026
                </h4>
                <div className="mt-4">
                  <a href="#" className="text-dark mx-3">
                    <i
                      className="fa fa-facebook"
                      style={{ fontSize: "23px" }}
                    ></i>
                  </a>
                  <a href="#" className="text-dark mx-3">
                    <i
                      className="fa fa-instagram"
                      style={{ fontSize: "23px" }}
                    ></i>
                  </a>
                  <a href="#" className="text-dark mx-3">
                    <i
                      className="fa fa-twitter"
                      style={{ fontSize: "23px" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
