import React from "react";

export default function MobileAppSection() {
  return (
    <div>
      {/* -------------------mobile app section------------------------ */}

      <div className="download-app-section my-5 " id="download-app">
        <div className="container position-relative">
          <div className="row d-flex justify-content-between">
            <div class="col-lg-4 col-md-12 col-sm-12 mt-10">
              <div class="content mt-5 text-center ">
                <h2 className="my-3">ScrapItUp</h2>
                <h4 className="scrap-h5-text f-16">
                  India's No. 1 Scrap Pickup App Providing door-to-door free
                  pickup service of 40+ recyclables.
                </h4>
                <button className="btn-style my-3  mb-5 f-16">
                  Download Our App
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 mobile-app d-flex justify-content-center ">
              <img
                className="img-fluid my-image"
                src="/assets/img/mobile-app-qr.png"
                alt="download-our-app"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
