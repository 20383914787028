import './App.css';
import HeroSection from './components/HeroSection';
import MobileAppSection from './components/MobileAppSection';
import Navbar from './components/Navbar';
import ServicesSection from './components/ServicesSection';
import WhyUs from './components/WhyUs';
import HouseCompanies from './components/HouseCompanies.js';
import TrustedCompanies from './components/TrustedCompanies.js';
import ClientSays from './components/ClientSays.js';
import Footer from './components/Footer.js';
import ContactUs from './components/ContactUs.js';

function App() {
  return (
   <>
   <div className='hero-wrapper'>
   <Navbar/>
   <HeroSection/>
   <MobileAppSection/>
   <ServicesSection/>
   <WhyUs/>
   <HouseCompanies/>
   <TrustedCompanies/>
   <ClientSays/>
   <ContactUs/>
   <Footer/>
   </div>
   
   </>
  );
}

export default App;
