import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function HouseCompanies() {

    const heading6 = useRef(null);
   

    useEffect(() => {
        gsap.fromTo(
          heading6.current,
          { scale: 0.8 },
          {
            scale: 1,
            duration: 1,
            ease: 'power2.out',
            scrollTrigger: {
              trigger: heading6.current,
              start: 'top 80%',
              end: 'top 20%',

              scrub: 1,
            },
          }
        ); 
    
      }, []);

      
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="container overflow-hidden">
      <h3 className="text-center my-3"ref={heading6}>
        Unique Services for  <span className='green'> Homes and Companies</span>
      </h3>
       <p className="text-center">
        We specialize in providing seamless scrap collection services for both
        homes and businesses. Our solutions are designed to make waste
        management easy, efficient, and reliable, tailored to meet the specific
        needs of households and companies
      </p> 

      <div className="card shadow p-3 rounded-5 card-style-long">
        <div className="row align-items-center">
          {/* Text Section */}
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h2 className="green">For Homes</h2>
            <h4 className="f-20">On-Demand Doorstep Pickups</h4>
            <p className="f-16">
              We make scrap collection simple by picking it up directly from
              your doorstep.
            </p>
            <h4 className="f-20">Accurate Digital Weighing</h4>
            <p className="f-16">
              Enjoy complete transparency with precise, digital weighing
              systems.
            </p>
            <h4 className="f-20">Safe & Verified Staff</h4>
            <p className="f-16">
              Our team is thoroughly trained and verified to ensure a safe
              experience for your household.
            </p>
          </div>

          {/* Image Section */}
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
            <img
              src="/assets/img/for-house.png"
              alt="for-house"
              className="img-fluid"
              style={{ maxWidth: "80%", height: "" }}
            />
          </div>
        </div>
      </div>

      {/* -----------------------for comapnies-------------------------------------- */}

      <div className="card shadow p-3 rounded-5 card-style-long my-5">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-3 d-flex justify-content-center">
            <img
              src="/assets/img/for-companies.png"
              alt="for-house"
              className="img-fluid"
              style={{ maxWidth: "100%", height: "" }}
            />
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
            <h2 className="green">For Companies</h2>
            <h4 className="f-20">Secure Payments & Detailed Documentation</h4>
            <p className="f-16">
              Get professional invoices and seamless payment options for all
              transactions.
            </p>

            <h4 className="f-20">Comprehensive Sustainability Reports</h4>
            <p className="f-16">
              Track your environmental impact with in-depth sustainability
              reports.
            </p>

            <h4 className="f-20">Competitive Pricing</h4>
            <p className="f-16">
              We provide highly competitive rates tailored to your business
              needs.
            </p>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------------comapnies---------------------------------------------- */}
    </div>
  );
}
